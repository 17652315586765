import React from 'react';
import ReactDOM from 'react-dom';
import App from'./app.js';
import Config from'./config'


if(localStorage.getItem('aaa_config') === null || localStorage.getItem('aaa_config') === ''){
	localStorage.setItem('aaa_config', JSON.stringify(Config));
}


const config = JSON.parse(localStorage.getItem('aaa_config'));


ReactDOM.render(
	<div>
		
  		<App />
  	</div>,
  document.getElementById('content')
);


let hide_logger = false;
//print console out to screen
/*
<div id="logger"><a href="#" id="close-logger" className="close"></a></div>

(function (logger) {
    console.old = console.log;
    console.log = function () {
        var output = "", arg, i;

        for (i = 0; i < arguments.length; i++) {
            arg = arguments[i];
            output += "<span class=\"log-" + (typeof arg) + "\">";

            if (
                typeof arg === "object" &&
                typeof JSON === "object" &&
                typeof JSON.stringify === "function"
            ) {
                output += JSON.stringify(arg);   
            } else {
                output += arg;   
            }

            output += "</span><hr/>";
        }

        logger.innerHTML += output + "";

		document.getElementById("logger").scrollTop = document.getElementById("logger").scrollHeight;


		document.getElementById("close-logger").addEventListener('click', (evt) => {  
			if(hide_logger){
				document.getElementById("close-logger").classList.remove("hide");
				document.getElementById("logger").classList.remove("hide");
				hide_logger = false;
			}else{
				document.getElementById("close-logger").classList.add("hide");
				document.getElementById("logger").classList.add("hide");
				hide_logger = true;
			}
		  
		});
    };
})(document.getElementById("logger"));
*/