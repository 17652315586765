import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
import Loading from './loading';
import Error from './error';
import Carousel from 'react-bootstrap/Carousel';

import { Auth } from 'aws-amplify';

import Config from './config'

if(localStorage.getItem('aaa_config') === null || localStorage.getItem('aaa_config') === ''){
  localStorage.setItem('aaa_config', JSON.stringify(Config));
}


const config = JSON.parse(localStorage.getItem('aaa_config'));

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      loading: true,
      loadingMessage: 'Loading...',
      error: null,
      signInState: 'signed_out'
    };
  }
  componentDidMount() {

    Auth.currentSession()
      .then(res => {
        this.setState({
            signInState: 'signed_in'
          });
      })
    //look in the address bar to check if there is an error message 
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const error = params.get('error_description'); 
    if(error!=null){
      this.setState({error:error})
    }
    let request_objects = {
      method: 'GET',
      headers: {
        'X-App-Id': 'xxxxx'
      },
      redirect: 'follow'
    };

    

    let request_url =
      config.catalog_settings.endpoint +
      '/A/'+
      config.locales.default+
      '/PCTV_DASH/'+
      config.catalog_settings.tenant+
      '/TRAY/SEARCH/VOD?filter_objectSubtype=SERIES&filter_propertyName='+
      config.catalog_settings.tenant+
      '&from=0&to=3';

    

    fetch(request_url, request_objects)
      .then(response => response.json())
      .then(data => {
       
        this.setState({ videos: data.resultObj.containers });
        this.setState({ loading: false });

      })
      .catch(err => console.error('Caught error: ', err));
  }

  render() {
    if(this.state.signInState === 'signed_in'){
      return (
        <div>
          <Error error={this.state.error} />
          <h1>Welcome to {config.name}</h1>
          <div className="rounded bg-light p-3">
            <Loading
              state={this.state.loading}
              message={this.state.loadingMessage}
              locale={this.props.locale}
            />

            <Carousel>
              {this.state.videos.map((value, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={
                        config.images.endpoint +
                        value.metadata.pictureUrl +
                        '/' +
                        value.metadata.pictureUrl +
                        '_FEATURED_TABLET.jpg'
                      }
                    />
                    <Carousel.Caption>
                      <h2>{value.metadata.title}</h2>
                      <p>{value.metadata.shortDescription}</p>
                      <a
                        className="btn btn-primary"
                        href={'/play/' + value.id}
                        role="button"
                      >
                        Watch
                      </a>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      )
    }else{
      return(
        <div>
          <h1>Welcome to the WarnerMedia VOD Test Harness</h1>
          <p>This web application allows you to test configuration settings for AAA (Authentication, Authorization and Accounting), catalogue and content delivery from AVS, and media playback via the WMGP player wrapper (with or without drm).</p>
          <p>In the <a href="/edit">configuration editor</a>, you can import a configuration file from a valid URL, tweak settings and save out to a JSON file.</p>
          <p>In the <a href="/MOVIE/null">video player test suite</a>, you can import HLS and DASH video playlists, test player settings, and see results of AAA or custom DRM.</p>
          <p>To test catalogue listings, pairing, etc.. you will need to be <a href="#"            onClick={() => Auth.federatedSignIn({ provider: 'WMID' })}>logged in </a>(you will need a valid username and password)</p>
        </div>
      )
    }
  }
}
export default Home;
