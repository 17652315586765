import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';

const config = JSON.parse(localStorage.getItem('aaa_config'));

class EpisodeThumbnail extends React.Component {

	render() {
		return (
			<div className="col-md-12 card mb-2 p-2">

				<h3>{this.props.data.metadata.title}: Episode {this.props.data.metadata.episodeNumber} </h3>
				<p>{this.props.data.metadata.longDescription}</p>
				<a className="btn btn-primary" href={this.props.link} role="button">Watch episode</a>

			</div>
		)
	}
}
export default EpisodeThumbnail