export default function fetchProxy(url, options) {
  return fetch(url, options)
    .then(response => response.json())
    .then(data => {
      //AVS always returns a 200 code, even for errors, so this does basic error checking
      if (data.errorDescription === '200-10000'||data.resultCode!='KO') {
        console.log('success', data);
        return data;
      } else {
        //you can easily look
        console.error('error', data);
        return Promise.reject(data);
      }
    });
}
