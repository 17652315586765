import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
//AWS cognito stuff
import Amplify, { Hub, Auth } from 'aws-amplify';
import { Authenticator,withOAuth } from 'aws-amplify-react'; 



class Login extends React.Component {
  constructor(props) {
    super(props);
    //set initial state
    this.state = {
      user: null,
      attributes: null,
      avs: null,
      signInState: 'signed_out'
    };
  }
  componentDidMount() {
    //check to see if the user is logged in already
    //ad listener for auth events
    if(!Auth.currentSession()){
    	localStorage.removeItem('amplify-signin-with-hostedUI');
    }
    Hub.listen('auth', data => {
      const { payload } = data;
      console.log('A new auth event has happened: ', data);
      if (payload.event === 'signIn') {
        //store payload in user state
        console.log('Sign in');
        this.setState({
          user: payload,
          attributes: null,
          signInState: 'signed_in',
          avs: null
        });
      }
      if (payload.event === 'signOut') {
        //remove user from state
        console.log('Sign out');
        this.setState({
          user: null,
          attributes: null,
          signInState: 'signed_out',
          avs: null
        });
        localStorage.removeItem('amplify-signin-with-hostedUI');
      }
    });
  }

  render() {
    return(
      <Authenticator />
    );
   
  }
}

export default withOAuth(Login);


