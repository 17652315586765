var Config = {
  "name": "CNPLUS",
  "metadata": {},
  "auth_settings": {
    "oidc": {
      "identityPoolRegion": "eu-west-1",
      "userPoolId": "eu-west-1_pFJrO40z8",
      "userPoolWebClientId": "5r13dm946nuldl6ibfdrsea41d",
      "mandatorySignIn": false,
      "federatedSignIn": {
        "provider": "WMID"
      },
      "oauth": {
        "domain": "aaa-cnplus-stg.auth.eu-west-1.amazoncognito.com",
        "scope": [
        "openid"
        ],
        "redirectSignIn": "http://localhost:3000",
        "redirectSignOut": "http://localhost:3000",
        "provider": "WMID",
        "responseType": "code"
      },
      "federationTarget": "COGNITO_USER_POOLS"
    },
    "logout": ""
  },
  "player_settings": {
    "endpoint": "https://avs-proxy.stg.aaa.wmgp.io/v1",
    "theme": "https://sometheme",
    "analytics": {},
    "qos": {},
    "streams": [
    "hls",
    "dash"
    ],
    "platforms": [
    "IPHONE",
    "PCTV_DASH"
    ],
    "tenant": "CNPLUS_LATAM_LATAM",
    "drm": {
      "widevine": {
        "LA_URL": "https://widevine.license.istreamplanet.com/widevine/api/license/7837c2c6-8fe4-4db0-9900-1bd66c21ffa3"
      },
      "fairplay": {
        "LA_URL": "https://fairplay.license.istreamplanet.com:443/api/license/7837c2c6-8fe4-4db0-9900-1bd66c21ffa3",
        "certificateURL": "https://fairplay.license.istreamplanet.com:443/api/AppCert/7837c2c6-8fe4-4db0-9900-1bd66c21ffa3"
      },
      "playready": {
        "LA_URL": "https://playready.license.istreamplanet.com/api/license"
      }
    }
  },
  "catalog_settings": {
    "endpoint": "https://avs-proxy.stg.aaa.wmgp.io/v1",
    "tenant": "CNPLUS_LATAM_LATAM",
    "platforms": [
    "PCTV_DASH"
    ]
  },
  "images": {
    "endpoint": "https://ti-content-global.cdn.turner.com/TEST-GLOBAL",
    "urlTemplate": "/<contentId>/<contentId>_<imageType>.<[jpg,png]>",
    "vtt": "/<contentId>/SPRITES/sprite_180p.vtt",
    "thumbs": "/<contentId>/THUMBNAILS/<contentId>_VIDSCREENSHOT_30_0.png"
  },
  "ageRating": "https://appconfigs.dev.wmgp.io/common/age_rating.json",
  "locales": {
    "default": "eng",
    "availability": [
    "spa",
    "por",
    "eng"
    ]
  },
  "killSwitch": {
    "alertMessage": "Hey a new version is out, you need to update!",
    "alertTitle": "Please Update",
    "androidDownloadUrl": "https://itunes.apple.com",
    "iosDownloadUrl": "https://itunes.apple.com",
    "shouldForceUpgrade": false,
    "versionNumberTriggerBelow": 0.1
  }
}
if(window.location.host === 'test-harness.dev.aaa.wmgp.io'){
  Config.auth_settings.oidc.oauth.redirectSignIn = Config.auth_settings.oidc.oauth.redirectSignOut = 'https://test-harness.dev.aaa.wmgp.io';
}

export default Config