import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import React from 'react';
import { Auth } from 'aws-amplify';
import Loading from './loading';
import Error from './error';
import YorhaBitmovin from '@tides/yorha-react';

const config = JSON.parse(localStorage.getItem('aaa_config'));
window.turner_metadata = {};

class MiniPlayer extends React.PureComponent{
    shouldComponentUpdate(nextProps, nextState){
        return false
    }

    constructor(props) {
        super(props);
        this.state = {
          drm: null,
          mpd: null,
          loading: true,
          loadingMessage: 'Loading',
          meta: {},
          idToken:null,
          error: null,
          sources:{},
          //switches for test harness
          drmBackup:'',
          css:'https://cn.i.cdn.ti-platform.com/static/css/cartoon.css',
          playerEnabled:true,
          adTagUrl:'',
          autoplay:true,
          drmEnabled:true,
          audioTracks:[],
          videoQualities:[],
          player:null,
          meta:null,
          youboraAccountCode:'turnerlatamdev',
          contentIsLive:null,
          stream_type:null
        };
    
        //bind functions to constructor
        this.yorha = React.createRef();
    
    
      }
      

      
    render(){
        return(
            <div id="miniplayer">
                <YorhaBitmovin
              ref={this.yorha}
              license='4d2865e0-6ab6-46f4-9217-ac26e9c75dc9'
              customStyle={this.state.css}
              preferredLanguage='SPA'
              ispToken={this.state.drm}  
              widevineLAUrl={config.player_settings.drm.widevine.LA_URL}
              fairplayLAUrl = {config.player_settings.drm.fairplay.LA_URL}
              fairplayCertificateUrl = {config.player_settings.drm.fairplay.certificateURL}
              playreadyLAUrl = {config.player_settings.drm.playready.LA_URL}
              source={this.state.sources}
              autoplay={this.state.autoplay}
              muted={true}
              chromecast={true}
              chromecastReceiverId={config.player_settings.chromecastReceiverId}
              chromecastParams={{
                  currentMediaURL: this.props.id,
                  contentId: this.props.id,
                  contentType: 'video/mp4',                 
                  streamType: 'VOD',                  
                  customData: {
                     contentId: this.props.id,
                     brand: config.name,
                     device: 'test_harnes',
                     JWTToken: this.state.idToken,
                     region: config.catalog_settings.tenant,
                  }           
              }}


              oneTrust={true}
              adTagUrl={this.state.adTagUrl}
              eventHandlers={
                  {
                    onPlay: () => {
                        console.log('Player Ready');
                        window.turner_metadata.trackAction.push({
                            type : "video",
                            subtype : "Player_Ready",
                            data : {
                                playerid: Math.random() * new Date.getTime(), /* unique player id if multiple instances on a page */
                                content_duration: 0, /* video length in seconds */
                                content_dataCreated: "", /* YYYY-MM-DD (e.g. 2018-08-30) */
                                content_dataAired: "", /* YYYY-MM-DD (e.g. 2018-08-30) */
                                content_name: this.state.meta.title, /* video title */
                                content_id: this.state.meta.contentId, /* video id */
                                content_type: this.state.stream_type, /* clip, vod, live */
                                content_showName: "", /* show name */
                                content_seasonNumber: this.state.meta.season, /* season number (e.g. 1, 2, 3, etc.) */
                                content_episodeNumber: this.state.meta.episodeNumber, /* episode number (e.g. 1, 2, 3, etc.) */
                                content_genre: this.state.meta.genres.toString(), /* genre (e.g. drama, comedy, etc.) */
                                content_rating: "", /* rating (e.g. tvy, tvg, tvpg, tvm, etc.) */
                                content_originator: this.state.meta.contentProvider, /* originator (e.g. warnermedia, sony, disney, etc.) */
                                content_network: "", /* network (e.g. fox, brabo, espn, etc.) */
                                content_mvpd: "", /* mvpd (e.g. comcast, directv, dish, etc.) */
                                content_authorized: "", /* true or false */
                                day_part: "", /* time of day (e.g. morning, daytime, primetime, etc.) */
                                content_feed: "" /* feed (e.g. east-hd, west-hd, east-sd, etc.) */
                            }
                        });                    
                        this.setState({audioTracks:this.yorha.current.state.player.getAvailableAudio()});
                    },

                      onAdStarted: () => {
                          console.log('Ad Started');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Ad_Started",
                              data : {
                                  ad_id: "pre-roll-1", /* ad identifier */
                                  ad_duration: 0, /* ad duration in seconds */
                                  ad_type: "preroll" /* ad type (e.g. preroll, midroll, postroll) */
                              }
                          });
                      },
                      onAdSkipped: () => {
                          console.log('Ad Skipped');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Ad_Skipped",
                              data : {}
                          });
                      },

                      onAdFinished: () => {
                          console.log('Ad Finished');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Ad_Finished",
                              data : {}

                          });
                      },

                      onPlaying: () => {
                          console.log('Media Started/Playing');
                          this.setState({player:this.yorha.current});
                          window.player = this.yorha.current.state.player;


                          this.setState({audioTracks:this.yorha.current.state.player.getAvailableAudio()});
                          this.setState({videoQualities:this.yorha.current.state.player.getAvailableVideoQualities()});

                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Started",
                              data : {}
                          })
                      },

                      onStallStarted: () => {
                          console.log('Media Buffering Started');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Buffering_Started",
                              data : {}
                          });
                      },

                      onStallEnded: () => {
                          console.log('Media Buffering Finished');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Buffering_Finished",
                              data : {}
                          });
                      },

                      onPause: () => {
                          console.log('Media Paused');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Paused",
                              data : {}
                          });
                      },

                      onPlaybackFinished: () => {
                          console.log('Playback Finished');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Finished",
                              data : {}
                          });
                      }
                  }
              }              
              customErrors={{
                  1401: 'AAAHHHH NOOOOOOOO'
              }}
            />
            </div>
        )
    }    
}

export default MiniPlayer