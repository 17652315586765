import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';

import Config from './config'

const config = JSON.parse(localStorage.getItem('aaa_config'));

const queryString = window.location.search;

if(localStorage.getItem('aaa_config') === null || localStorage.getItem('aaa_config') === ''){
  
  localStorage.setItem('aaa_config', JSON.stringify(Config));
}


class Editor extends React.Component {
  constructor(props) {
    super(props);
    //set initial state

    this.updateConfig = this.updateConfig.bind(this);
    this.resetConfig = this.resetConfig.bind(this);
    this.exportConfig = this.exportConfig.bind(this);
    this.importConfig = this.importConfig.bind(this);

  }

  componentWillMount(){
    /*
    const urlParams = new URLSearchParams(queryString);
    const c = urlParams.get('config')
    if(c != null || c !=''){
      this.importConfig(c);
      console.log(c)
    }
    */
  }
  componentDidMount() {
    //check to see if the user is logged in already
    //ad listener for auth events
    
  }

  updateConfig(){
    localStorage.setItem('aaa_config', JSON.stringify(config));
    window.location.reload();
  }

  resetConfig(){
    localStorage.setItem('aaa_config', JSON.stringify(Config));
    window.location.reload();
  }

  exportConfig(){
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(config)], {type: 'json'});
    a.href = URL.createObjectURL(file);
    a.download = 'config.json';
    a.click();
  }

  importConfig(e){
    console.log('import',e);

    if (typeof e === 'string'){
      console.log('string')
      var val = e;
    }else{
      var val = document.getElementById('configURL').value;
    }
    
    //console.log(val.value);
    let request_objects = {
          method: 'GET'
        };
    fetch(val,request_objects)
          .then(response => response.json())
          .then(data => {
            console.log(data);
            //this.setState({ data: data.resultObj.containers[0] });
            if(window.location.host === 'test-harness.dev.aaa.wmgp.io'){
              data.auth_settings.oidc.oauth.redirectSignIn = 'https://test-harness.dev.aaa.wmgp.io';
              data.auth_settings.oidc.oauth.redirectSignOut = 'https://test-harness.dev.aaa.wmgp.io';
            }
            var o = {...config,...data}
            localStorage.setItem('aaa_config', JSON.stringify(o));

            window.location.reload();
            
          
      }).catch(err => console.error('Caught error: ', err));

  }

  render() {
    return(
      <div>
        <div className="container bg-white p-3 mb-5">

          <div className="form-group border p-3 mt-3">
          <h2>Load predefined config file</h2>
          <label htmlFor="configURL">Load from URL</label>
            <input type="text" className="form-control" id="configURL"  />
            <br/>
            <button type="button" className="btn btn-info" onClick={this.importConfig}>Import config</button>
            <br />
            <p>- or -</p>
            <label htmlFor="configURLSelect">Select file</label>
            <select className="form-control" id="configURLSelect" onChange={(e)=> {this.importConfig(e.target.value)}}>
              <option value="" disabled selected>Select----></option>
              <option value="https://appconfigs.dev.wmgp.io/cdf/web/config.json">CDF development</option>
              <option value="https://appconfigs.stg.wmgp.io/cdf/web/config.json">CDF staging</option>
              <option value="https://appconfigs.dev.wmgp.io/cnplus/web/config.json">CN+ development</option>
              <option value="https://appconfigs.stg.wmgp.io/cnplus/web/config.json">CN+ staging</option>
            </select>

          
          </div>

          <div className="form-group border p-3">
            <h2>Cognito setup for {config.name}</h2>
            <label htmlFor="identityPoolRegion">Identiy pool region</label>
            <input type="text" className="form-control" id="identityPoolRegion" defaultValue={config.auth_settings.oidc.identityPoolRegion} onChange={(e) => config.auth_settings.oidc.identityPoolRegion = e.target.value} />

            <label htmlFor="userPoolId">User pool ID</label>
            <input type="text" className="form-control" id="userPoolId" defaultValue={config.auth_settings.oidc.userPoolId} onChange={(e) => config.auth_settings.oidc.userPoolId = e.target.value}/>

            <label htmlFor="userPoolWebClientId">Web client ID</label>
            <input type="text" className="form-control" id="userPoolWebClientId" defaultValue={config.auth_settings.oidc.userPoolWebClientId} onChange={(e) => config.auth_settings.oidc.userPoolWebClientId = e.target.value}/>


            <label htmlFor="federatedSignIn">Federated sign-in provider</label>
            <input type="text" className="form-control" id="federatedSignIn" defaultValue={config.auth_settings.oidc.federatedSignIn.provider} onChange={(e) => config.auth_settings.oidc.federatedSignIn.provider = config.auth_settings.oidc.federationTarget = e.target.value}/>
            <br />

            <h3>OAUTH</h3>
            <label htmlFor="domain">Domain</label>
            <input type="text" className="form-control" id="domain" defaultValue={config.auth_settings.oidc.oauth.domain} onChange={(e) => config.auth_settings.oidc.oauth.domain = e.target.value}/>

            <label htmlFor="redirectSignIn">Redirect URL after sign-in</label>
            <input type="text" className="form-control" id="redirectSignIn" defaultValue={config.auth_settings.oidc.oauth.redirectSignIn} onChange={(e) => config.auth_settings.oidc.oauth.redirectSignIn = e.target.value}/>

            <label htmlFor="redirectSignOut">Redirect URL after sign-out</label>
            <input type="text" className="form-control" id="redirectSignOut" defaultValue={config.auth_settings.oidc.oauth.redirectSignOut} onChange={(e) => config.auth_settings.oidc.oauth.redirectSignOut = e.target.value}/>
          </div>






          <div className="form-group border p-3">
            <h2>Content setup</h2>

            <label htmlFor="endpoint">Catalogue and playback endpoint</label>
            <input type="text" className="form-control" id="endpoint" defaultValue={config.catalog_settings.endpoint} onChange={(e) => config.catalog_settings.endpoint = config.player_settings.endpoint = e.target.value} />

          </div>









          <button type="button" className="btn btn-primary" onClick={this.updateConfig}>Update</button>
          <button type="button" className="btn btn-danger ml-3" onClick={this.resetConfig}>Reset</button>
          <button type="button" className="btn btn-info ml-3" onClick={this.exportConfig}>Export config</button>

          
        </div>
      </div>
    );
  }
}

export default Editor;


