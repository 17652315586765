import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
//AWS cognito stuff
import Amplify, { Hub, Auth } from 'aws-amplify';
import { Authenticator,withOAuth } from 'aws-amplify-react'; 
import PinInput from 'react-pin-input'
import Cognito from'./cognito'

import Loading from './loading';

const config = JSON.parse(localStorage.getItem('aaa_config'));


class Pair extends React.Component {
  constructor(props) {
    super(props);
    //set initial state
    this.state = {
      user: null,
      attributes: null,
      avs: null,
      signInState: 'signed_out',
      pin:null,
      index:null,
      loading: false,
      loadingMessage: 'Loading',
      success:null,
      url:null
    };
    this.checkPin = this.checkPin.bind(this);
  }
  componentDidMount() {
    //check to see if the user is logged in already
    //ad listener for auth events
    if(config.auth_settings.pair != null){
      this.setState({url:config.auth_settings.pair.replace('activate','pin')})
    }else{
      this.setState({url:'https://pin-and-pair.dev.aaa.wmgp.io/v1/activate'.replace('activate','pin')})
    }

  }

  checkPin(value,index) {

    if(config.auth_settings.pair != null){
      var url = config.auth_settings.pair;
    }else{
      var url = 'https://pin-and-pair.dev.aaa.wmgp.io/v1/activate';
    }


    this.setState({pin:value,index:index});
    this.setState({ loading: true });
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'POST',
          mode: 'cors',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          },          
          body: JSON.stringify({"pin":this.state.pin})
        }
        fetch(url, request_objects)
        //.then(response => response.json())
        .then(data => {
          console.log(data);
          //if you recieve a payload it should be successful
          if(data.ok){
            console.log('Successfully paired')
            this.setState({success:'Successfully paired with your TV'})
          }
          this.setState({ loading: false });
        })
        .catch(err => {
          //if there is an error/cors issue returned, the pin is incorrect
          console.error('Pairing has failed ', err);
          this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
          this.setState({ loading: false });
        });
      })
  }

  render() {
    if(Auth.currentSession()){
      return(
        <div>
        <Loading
            state={this.state.loading}
            message={this.state.loadingMessage}
            locale={this.props.locale}
          />
          <div className="container bg-white text-center p-3">
          <p>Please enter the pin displayed on your TV</p>
            <PinInput 
              length={6} 
              initialValue="" 
              onChange={(value, index) => {}} 
              type="custom" 
              style={{padding: '10px'}}  
              inputStyle={{borderColor: 'red'}}
              inputFocusStyle={{borderColor: 'blue'}}
              onComplete={this.checkPin}
            />
            {this.state.success}
            <p><a href={this.state.url} target="_blank">Launch a new window to generate pin if tv app not available</a></p>
          </div>
        </div>
      );
    }else{
      return(
        <div>
          <div className="container bg-white text-center p-3">
            <p>Sorry, you must login before you can pair with your TV.</p>
            <Cognito locale={this.props.locale} />
          </div>
        </div>
      )
    }
   
  }
}

export default Pair;


