import React from 'react';
import { Auth } from 'aws-amplify';
import Loading from './loading';
import Error from './error';
import YorhaBitmovin from '@tides/yorha-react';

const config = JSON.parse(localStorage.getItem('aaa_config'));
window.turner_metadata = {};

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drm: null,
      mpd: null,
      loading: true,
      loadingMessage: 'Loading',
      meta: {},
      idToken:null,
      error: null,
      sources:{},
      //switches for test harness
      drmBackup:'',
      css:'https://cn.i.cdn.ti-platform.com/static/css/cartoon.css',
      playerEnabled:true,
      adTagUrl:'',
      autoplay:true,
      drmEnabled:true,
      audioTracks:[],
      videoQualities:[],
      player:null,
      meta:null,
      youboraAccountCode:'turnerlatamdev',
      contentIsLive:null,
      stream_type:null
    };

    //bind functions to constructor
    this.updatePlayer = this.updatePlayer.bind(this);
    this.yorha = React.createRef();


  }
  componentDidMount() {
    //Get current authentication session
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        this.setState({idToken:idToken})
        let request_objects = {
          method: 'GET',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }
        };

        console.log('The stream is live?',this.prop)
        if (this.props.live){
          var stream_type = 'LIVE'
          this.setState({stream_type:"LIVE",contentIsLive:true})
        }else{
          var stream_type = 'VOD'
          this.setState({stream_type:"VOD",contentIsLive:false})
        }
        //get video meta data as a seperate request
        fetch(
              config.player_settings.endpoint +
              '/A/'+
              config.locales.default+
              '/'+
              config.player_settings.platforms[0]+
              '/'+
              config.player_settings.tenant+
              '/'+
              'content/detail/video/'+
              this.props.id,
              request_objects
            )
            .then(response => response.json())
            .then(data => {
              console.log('meta data:',data.resultObj.content_metadata)
              //store meta data
              this.setState({meta:data.resultObj.content_metadata})
            })      


        //cycle through sources and call the stop endpoint to stop timing out when 1 user is trying to start multiple streams
        for(var i=0; i < config.player_settings.platforms.length; i++){
        let request_url =
          config.player_settings.endpoint +
            '/R/'+
            config.locales.default+
            '/'+
            config.player_settings.platforms[i]+
            '/'+
            config.player_settings.tenant+
            '/'+
            'CONTENT/STOP?contentId=' +
            this.props.id +
            '&asJson=Y&type='+stream_type+'&bookmark=0&deltaThreshold=0';



        fetch(request_url, request_objects)
          .then(response => response.json())
          .then(data => {
            console.log('stopped?',data);
            this.setState({loadingMessage: 'Stopping: '+request_url});
          });
        }
        //eng/PCTV/SPORTS_LATAM_CDF/CONTENT/STOP?contentId=1000087427&asJson=Y&type=VOD&bookmark=0&deltaThreshold=0
        //make an array of promises to get all endpoints in config
        let promises =[];
        for(var i=0; i < config.player_settings.platforms.length; i++){
          promises.push(
            //construct url from global config to fetch video path from each stream
            fetch(
              config.player_settings.endpoint +
              '/R/'+
              config.locales.default+
              '/'+
              config.player_settings.platforms[i]+
              '/'+
              config.player_settings.tenant+
              '/'+
              'content/cdn'+
              '?id='+
              this.props.id+
              '&type=VOD&asJson=Y',
              request_objects
            )
            .then(response => response.json())            
          )
        }
        Promise.all(promises)
        .then(data => {
          this.setState({loadingMessage: 'Getting video sources'})
          console.log(data);
          //construct a source object to pass all available streams to the player
          let source = {};
          for(var i=0; i < data.length; i++){
            //populate source object if the stream exists
            if(data[i].resultObj.src){
              //check that there is no error
              if(data.resultCode!="KO"){
                //is it VOD?
                if(data[i].resultObj.src.includes('mpd')){
                  source.dash = data[i].resultObj.src;
                }
                //is it a live stream?
                if(data[i].resultObj.src.includes('m3u8')){
                  source.hls = data[i].resultObj.src;
                }
              }
                  
                  
            }
          }
          this.setState({sources:source})
          console.log('length:',source.length)
            //construct url from global config to fetch drm token
      			fetch(
  	          config.player_settings.endpoint +
              '/R/'+
              config.locales.default+
              '/'+
              config.player_settings.platforms[1]+
              '/'+
              config.player_settings.tenant+
              '/'+
              'CONTENT/GETDRMTOKEN'+
              '/'+
              this.props.id,
  	          request_objects
  	        )
  	        .then(response => response.json())
  	        .then(data => {
              this.setState({loadingMessage: 'Getting DRM'})
              //save token and content url in state
              console.log(data)
              if(data.resultCode === "KO" || data.errorDescription =='No Data Found'){
                //no token, dont pass it to player
                this.setState({ drm: '', error:'DRM could not be resolved: '+data.message,drmEnabled:false });
                this.setState({ loading: false });
              }else{
                //add drm to state
                this.setState({ drm: data.resultObj });
                this.setState({ loading: false });

              }
  	        })     
        })
        .catch(err => {
          //drm token is not found/broken - handle error
          this.setState({drmEnabled:false})
          console.log(err)
          //hide loading screen
          this.setState({ loading: false });
          this.setState({
            error: 'Sorry, you are not logged in - you will have to provide your own DRM'
          });
        });
      })
      .catch(err => {
        //video content is not found/broken - handle error
        this.setState({ loading: false });
        this.setState({
          error: 'Sorry, you are not logged in - you will have to provide your own DRM'
        });
      });
  }

  updatePlayer() {
    console.log('update');
    //force the player and states to update
    this.forceUpdate();
  }

  childFunction=(e)=>{
    e.preventDefault();
    this.props.functionCallFromParent("Hello From Child1");
  }

  render() {
    if(this.state.drm != null && Object.keys(this.state.sources).length > 0 && this.state.playerEnabled){
      //we have a valid drm and content stream, initialise the player
      console.log(this.state.drm);
      return (
        <div>
          <Loading
            state={this.state.loading}
            message={this.state.loadingMessage}
            locale={this.props.locale}
          />
          <Error error={this.state.error} />      
          <h2>{this.state.meta.title}</h2>
          <div className="bg-light p-3 rounded border-0">

            <YorhaBitmovin
              ref={this.yorha}
              license='4d2865e0-6ab6-46f4-9217-ac26e9c75dc9'
              customStyle={this.state.css}
              preferredLanguage='SPA'
              ispToken={this.state.drm}  
              widevineLAUrl={config.player_settings.drm.widevine.LA_URL}
              fairplayLAUrl = {config.player_settings.drm.fairplay.LA_URL}
              fairplayCertificateUrl = {config.player_settings.drm.fairplay.certificateURL}
              playreadyLAUrl = {config.player_settings.drm.playready.LA_URL}
              source={this.state.sources}
              autoplay={this.state.autoplay}
              muted={true}
              chromecast={true}
              chromecastReceiverId={config.player_settings.chromecastReceiverId}
              chromecastParams={{
                  currentMediaURL: this.props.id,
                  contentId: this.props.id,
                  contentType: 'video/mp4',                 
                  streamType: 'VOD',                  
                  customData: {
                     contentId: this.props.id,
                     brand: config.name,
                     device: 'test_harnes',
                     JWTToken: this.state.idToken,
                     region: config.catalog_settings.tenant,
                  }           
              }}

              youboraConfig={
                  {
                      accountCode: this.state.youboraAccountCode,
                      contentIsLive: this.state.contentIsLive,
                      contentTitle: this.state.meta.title
                  }
              }

              autoFetch={false}
              oneTrust={true}
              adTagUrl={this.state.adTagUrl}
              eventHandlers={
                  {
                    onPlay: () => {
                        console.log('Player Ready');
                        window.turner_metadata.trackAction.push({
                            type : "video",
                            subtype : "Player_Ready",
                            data : {
                                playerid: Math.random() * new Date.getTime(), /* unique player id if multiple instances on a page */
                                content_duration: 0, /* video length in seconds */
                                content_dataCreated: "", /* YYYY-MM-DD (e.g. 2018-08-30) */
                                content_dataAired: "", /* YYYY-MM-DD (e.g. 2018-08-30) */
                                content_name: this.state.meta.title, /* video title */
                                content_id: this.state.meta.contentId, /* video id */
                                content_type: this.state.stream_type, /* clip, vod, live */
                                content_showName: "", /* show name */
                                content_seasonNumber: this.state.meta.season, /* season number (e.g. 1, 2, 3, etc.) */
                                content_episodeNumber: this.state.meta.episodeNumber, /* episode number (e.g. 1, 2, 3, etc.) */
                                content_genre: this.state.meta.genres.toString(), /* genre (e.g. drama, comedy, etc.) */
                                content_rating: "", /* rating (e.g. tvy, tvg, tvpg, tvm, etc.) */
                                content_originator: this.state.meta.contentProvider, /* originator (e.g. warnermedia, sony, disney, etc.) */
                                content_network: "", /* network (e.g. fox, brabo, espn, etc.) */
                                content_mvpd: "", /* mvpd (e.g. comcast, directv, dish, etc.) */
                                content_authorized: "", /* true or false */
                                day_part: "", /* time of day (e.g. morning, daytime, primetime, etc.) */
                                content_feed: "" /* feed (e.g. east-hd, west-hd, east-sd, etc.) */
                            }
                        });                    
                        this.setState({audioTracks:this.yorha.current.state.player.getAvailableAudio()});
                    },

                      onAdStarted: () => {
                          console.log('Ad Started');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Ad_Started",
                              data : {
                                  ad_id: "pre-roll-1", /* ad identifier */
                                  ad_duration: 0, /* ad duration in seconds */
                                  ad_type: "preroll" /* ad type (e.g. preroll, midroll, postroll) */
                              }
                          });
                      },
                      onAdSkipped: () => {
                          console.log('Ad Skipped');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Ad_Skipped",
                              data : {}
                          });
                      },

                      onAdFinished: () => {
                          console.log('Ad Finished');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Ad_Finished",
                              data : {}

                          });
                      },

                      onPlaying: () => {
                          console.log('Media Started/Playing');
                          this.setState({player:this.yorha.current});
                          window.player = this.yorha.current.state.player;


                          this.setState({audioTracks:this.yorha.current.state.player.getAvailableAudio()});
                          this.setState({videoQualities:this.yorha.current.state.player.getAvailableVideoQualities()});

                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Started",
                              data : {}
                          })
                      },

                      onStallStarted: () => {
                          console.log('Media Buffering Started');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Buffering_Started",
                              data : {}
                          });
                      },

                      onStallEnded: () => {
                          console.log('Media Buffering Finished');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Buffering_Finished",
                              data : {}
                          });
                      },

                      onPause: () => {
                          console.log('Media Paused');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Paused",
                              data : {}
                          });
                      },

                      onPlaybackFinished: () => {
                          console.log('Playback Finished');
                          window.turner_metadata.trackAction.push({
                              type : "video",
                              subtype : "Media_Finished",
                              data : {}
                          });
                      }
                  }
              }              
              customErrors={{
                  1401: 'AAAHHHH NOOOOOOOO'
              }}
            />
            <br />

            <div className="form-group border p-3">
              <h3>Player controls</h3>
              <p>Select audio track</p>
              <div className="btn-group mb-3" role="group" aria-label="Basic example">
                
                {this.state.audioTracks.map((value,index)=>{
                  return(
                    <button type="button" className="btn btn-secondary" key={index} onClick={(e)=> {
                      this.yorha.current.state.player.setAudio(value.id);
                    }}>{value.label}</button>
                  )
                })}
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="customSwitch3" onChange={(e) => {
                    window.miniplayer = e.target.checked;
                    this.props.functionCallFromParent(e.target.checked);
                    localStorage.setItem('miniplayer',true);
                    this.yorha.current.state.player.togglePlay();
                  }}
                   />
                  <label className="custom-control-label" htmlFor="customSwitch3">Toggle miniplayer</label>
                </div>
              </div>
              <br />
              <p>Select video quality</p>
              <div className="btn-group mb-3" role="group" aria-label="Basic example">
                
                {this.state.videoQualities.map((value,index)=>{
                  return(
                    <button type="button" className="btn btn-danger" key={index} onClick={(e)=> {
                      this.yorha.current.state.player.setVideoQuality(value.id);
                    }}>{value.label}</button>
                  )
                })}

              </div>
              <br />

              <button type="button" className="btn btn-info mr-2" onClick={(e)=>this.yorha.current.state.player.toggleMute()}>Toggle mute</button>
              <button type="button" className="btn btn-info mr-2" onClick={(e)=>this.yorha.current.state.player.togglePlay()}>Toggle play</button>
              <button type="button" className="btn btn-info mr-2" onClick={(e)=>this.yorha.current.state.player.togglePictureInPicture()}>Toggle picture in picture</button>
              <button type="button" className="btn btn-info mr-2" onClick={(e)=>this.yorha.current.state.player.toggleFull()}>Toggle full screen</button>
              <button type="button" className="btn btn-info mr-2" onClick={(e)=>{
                this.yorha.current.state.player.castVideo();
                console.log('chromecast')
              }}>Chromecast</button>

            </div>


              
  




            <div className="form-group border p-3">
              <label htmlFor="css">Player style file</label>
              <input type="text" className="form-control" id="css" defaultValue={this.state.css} onChange={(e) => {
                  this.setState({css:e.target.value})                
                }} />
            </div>





            <div className="form-group border p-3">
              <h3>Pre initialization setup</h3>

              <label htmlFor="hls">HLS URL</label>
              <input type="text" className="form-control" id="hls" defaultValue={this.state.sources.hls} onChange={(e) => {
                  var newState = {...this.state.sources,...{hls:e.target.value}}
                  this.setState({sources:newState})                
                }} />

              <label htmlFor="dash">DASH URL</label>
              <input type="text" className="form-control" id="dash" defaultValue={this.state.sources.dash} onChange={(e) => {
                  var newState = {...this.state.sources,...{dash:e.target.value}}
                  this.setState({sources:newState})                  
                }} />

                <label htmlFor="css">Ad tag URL</label>
                <input type="text" className="form-control" id="adtag" defaultValue={this.state.adTagUrl} onChange={(e) => {
                  this.setState({adTagUrl:e.target.value})                
                }} />

                <label htmlFor="css">Youbora account code <span className="badge badge-info mr-1">LIVE?: {this.state.contentIsLive.toString()}</span><span className="badge badge-info">TITLE:{this.state.meta.title}</span></label>
                <input type="text" className="form-control" id="youboraAccountCode" defaultValue={this.state.youboraAccountCode} onChange={(e) => {
                  this.setState({youboraAccountCode:e.target.value})                
                }} />

                <label htmlFor="token">Custom DRM token</label>
                <textarea className="form-control" id="token" defaultValue={this.state.drm} onChange={(e) => {
                  this.setState({drm:e.target.value})                
                }} />


                <hr />
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch1" defaultChecked={this.state.drmEnabled} onChange={(e) => {
                  this.setState({drmEnabled:e.target.checked})
                  if(!e.target.checked){
                    this.setState({drm:''});
                  }
                  console.log(this.yorha.current)
                }} />
                <label className="custom-control-label" htmlFor="customSwitch1">Enable DRM</label>
              </div>


              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch2" defaultChecked={this.state.autoplay} onChange={(e) => {
                  this.setState({autoplay:!this.state.autoplay})

                }} />
                <label className="custom-control-label" htmlFor="customSwitch2">Autoplay video?</label>
              </div>


              <br />
              <button type="button" className="btn btn-info" onClick={(e)=>this.setState({playerEnabled:false})}>Destroy player</button> <p>You must destroy and re initialise the player for these changes to take effect</p>

            </div>
          </div>
        </div>
      )
    }else{
      //if there is no drm token, dont initialise the video!
      return(
        <div>
          <Loading
            state={this.state.loading}
            message={this.state.loadingMessage}
            locale={this.props.locale}
          />
          <Error error={this.state.error} />


          <div className="form-group border p-3">
              <h3>Pre initialization setup</h3>

              <label htmlFor="hls">HLS URL</label>
              <input type="text" className="form-control" id="hls" defaultValue={this.state.sources.hls} onChange={(e) => {
                  var newState = {...this.state.sources,...{hls:e.target.value}}
                  this.setState({sources:newState})                
                }} />

              <label htmlFor="dash">DASH URL</label>
              <input type="text" className="form-control" id="dash" defaultValue={this.state.sources.dash} onChange={(e) => {
                  var newState = {...this.state.sources,...{dash:e.target.value}}
                  this.setState({sources:newState})                  
                }} />

                <label htmlFor="css">Ad tag URL</label>
                <input type="text" className="form-control" id="css" defaultValue={this.state.adTagUrl} onChange={(e) => {
                  this.setState({adTagUrl:e.target.value})                
                }} />

                 <label htmlFor="token">Custom DRM token</label>
                <textarea className="form-control" id="token" defaultValue={this.state.drm} onChange={(e) => {
                  this.setState({drm:e.target.value})                
                }} />

                <hr />
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch1" defaultChecked={this.state.drmEnabled} onChange={(e) => {
                  this.setState({drmEnabled:e.target.checked})
                  if(!e.target.checked){
                    this.setState({drm:''});
                  }
                  console.log(this.yorha.current)
                }} />
                <label className="custom-control-label" htmlFor="customSwitch1">Enable DRM</label>
              </div>

              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch2" defaultChecked={this.state.autoplay} onChange={(e) => {
                  this.setState({autoplay:!this.state.autoplay})

                }} />
                <label className="custom-control-label" htmlFor="customSwitch2">Autoplay video?</label>
              </div>


              <br />
              <button type="button" className="btn btn-info" onClick={(e)=>this.setState({playerEnabled:true})}>Initialise player</button> <p>You must destroy and re initialise the player for these changes to take effect</p>

            </div>
            </div>
      )
    }
    
  }
}
export default Video;
