import React from 'react';
import Cognito from './cognito'
import {Link} from 'react-navi'
import Amplify, { Hub, Auth } from 'aws-amplify';

let config = JSON.parse(localStorage.getItem('aaa_config'));

class Navigation extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      signInState: 'signed_out'
	    };
	}

	componentDidMount() {

	    Auth.currentSession()
	      .then(res => {
	        this.setState({
	            signInState: 'signed_in'
	          });
	      })
	      Hub.listen('auth', data => {
		      const { payload } = data;
		      console.log('A new auth event has happened: ', data);
		      if (payload.event === 'signIn') {
		      	this.setState({
		            signInState: 'signed_in'
		        });
		      }
		      if (payload.event === 'signOut') {
		      	this.setState({signInState: 'signed_out'})
		      }
		  })
	}

	render() {
		if (this.state.signInState === 'signed_in') {
			return (
				<nav className="navbar navbar-expand navbar-fixed-top navbar-light bg-light main-nav main-header">
					<div className="container-fluid">
						<ul className="nav navbar-nav">
							<li className="nav-item">
							<Link className="nav-link" href="/"><div className="logo">{config.name}</div></Link>
							</li>
						</ul>


						<ul className="nav navbar-nav">
							<li className="nav-item">
								<Link className="nav-link" href="/">Home</Link>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/films">Films</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/series">Series</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/live">Live channels</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/pair">Pair with TV</a>
							</li>
							<li>
								<Cognito locale={this.props.locale} />
							</li>					
							
							<li className="nav-item">
								<a className="ml-3 nav-link btn btn-danger text-white" href="/edit">Edit configuration</a>
							</li>
						</ul>
					</div>
				</nav>
			)
		} else {
			return (
				<nav className="navbar navbar-expand navbar-fixed-top navbar-light bg-light main-nav main-header">
					<div className="container-fluid">
						<ul className="nav navbar-nav">
							<li className="nav-item">
								<a className="nav-link" href="/"><div className="logo">{config.name}</div></a>
							</li>
						</ul>


						<ul className="nav navbar-nav">
							<li className="nav-item">
								<a className="ml-3  btn btn-outline-info" href="/MOVIE/null">Test single video</a>
							</li>
							<li className="nav-item">
								<Cognito locale={this.props.locale} />
							</li>					
							
							<li className="nav-item">
								<a className="ml-3 btn btn-danger text-white" href="/edit">Edit configuration</a>
							</li>
						</ul>
					</div>
				</nav>
			)
		}
	}
}

export default Navigation