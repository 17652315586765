import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';

const config = JSON.parse(localStorage.getItem('aaa_config'));

class Profile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			profile: {},
			videos: [],
			avs: this.props.avs,
			mpd: null,
			drm: null
		};
	}
	componentDidMount() {


		Auth.currentSession().then(res => {
			console.log(res.idToken.payload)
			this.setState({profile:res.idToken.payload})
			//let idToken = res.getIdToken().getJwtToken();
			//let request_objects = {
			//	method: 'GET',
			//	headers: {
			//		'Accept': '*/*',
			//		'Content-Type': 'application/json',
			//		'Authorization': 'Bearer ' + idToken,
			//	}
			//}
			/*

			let request_url = process.env.REACT_APP_API_SERVER + 'avs/AGL/1.0/A/ENG/IPHONE/TNX_NORD_NO/TRAY/SEARCH/VOD?filter_objectSubtype=MOVIE&filter_propertyName=TNX_NORD_NO&from=0&to=99'
			fetch(request_url, request_objects)
				.then(response => response.json())
				.then(data => {
					console.log(data.results.resultObj.containers)
					this.setState({ videos: data.results.resultObj.containers })
				})
				.catch(err => console.error('Caught error: ', err));

			*/
		});
	}
	getToken() {
		Auth.currentSession().then(res => {
			let accessToken = res.getAccessToken()
			let idToken = res.getIdToken()
			console.log(idToken.getJwtToken())
			console.log('AccessToken:' + accessToken.getJwtToken())
		});
	}
	
	render() {
		//if (this.props.authState === "signedIn") {
			return (
				<div>

					<h2>Profile</h2>
					<hr />
					<p><strong>Username</strong>: {this.state.profile['cognito:username']}</p>
					<p><strong>Email</strong>: {this.state.profile.email}</p>
					
				</div>
			)
		//} else {
		//	return null;
		//}
	}
}
export default Profile