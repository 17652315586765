import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

class Error extends React.Component {

	constructor(props) {
		super(props);
		this.state={
			message:null
		}
	}

	componentDidMount() {
		this.setState({message:this.props.error})
	}

	render() {
		
		if(this.props.error){
			return (
				<div className="alert alert-danger">
					{this.props.error}
				</div>
			)
		}else{
			return null;
		}
		
	}
}

export default Error