import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';

import EpisodeThumbnail from './episodethumbnail';
import Loading from './loading';
import Error from './error';

const config = JSON.parse(localStorage.getItem('aaa_config'));

class EpisodesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      data: { contentObjects: [], metadata: {} },
      seasons: [],
      avs: this.props.avs,
      mpd: null,
      drm: null,
      loading: true,
      loadingMessage: 'Loading',
      error: null,
      
    };
  }
  componentDidMount() {
    Auth.currentSession()
      .then(res => {
        //get current token from cognito auth session
        //let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'GET'
        };
        let request_url =
          config.player_settings.endpoint +
            '/A/'+
            config.locales.default+
            '/'+
            config.catalog_settings.platforms[0]+
            '/'+
            config.player_settings.tenant+
            '/'+
            'content/detail/GOB/' +
            this.props.id;

        fetch(request_url, request_objects)
          .then(response => response.json())
          .then(data => {
            console.log(data);
            this.setState({ data: data.resultObj.containers[0] });
            this.setState({ loading: false });
            //loop through each season and retrieve episodes
            data.resultObj.containers[0].contentObjects.map((item, key) => {
              console.log(item.metadata.contentId);
              let episodes_url =
                config.player_settings.endpoint +
            '/A/'+
            config.locales.default+
            '/'+
            config.catalog_settings.platforms[0]+
            '/'+
            config.player_settings.tenant+
            '/'+
            'content/detail/BUNDLE/' +
            item.metadata.contentId;

              	fetch(episodes_url, request_objects)
                .then(response => response.json())
                .then(episode => {
                  console.log(episode.resultObj.containers[0]);
                  //this.setState({ seasons: episode.resultObj.containers[0] })
                  this.setState(prevState => ({
                    seasons: [
                      ...prevState.seasons,
                      episode.resultObj.containers[0]
                    ]
                  }));
                });
            });
          })
          .catch(err => console.error('Caught error: ', err));
      })
      .catch(err => {
        console.error('Caught error not logged in: ', err);
        this.setState({ loading: false });
        this.setState({
          error: 'Sorry, you are not logged in - please log in'
        });
      });
  }

  getToken() {
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken();
      let idToken = res.getIdToken();
      console.log(idToken.getJwtToken());
      console.log('AccessToken:' + accessToken.getJwtToken());
    });
  }

  render() {
    return (
      <div>
        <Error error={this.state.error} />
        <Loading
          state={this.state.loading}
          message={this.state.loadingMessage}
          locale={this.props.locale}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>
                {this.state.data.metadata.title}:{' '}
                episodes
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          {this.state.seasons.map((value, index) => {
            return (
              <div className="row" key={index}>
                <h2>Season {value.metadata.season}</h2>
                {value.contentObjects.map((value, index) => {
                  return (
                    <EpisodeThumbnail
                      data={value}
                      key={index}
                      link={'/MOVIE/' + value.metadata.contentId}
                      cta='Watch episode'
                      locale={this.props.locale}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <hr />
      </div>
    );
  }
}
export default EpisodesList;
