import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';

const config = JSON.parse(localStorage.getItem('aaa_config'));

class VideoThumbnail extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      image: ''
	    };
	}

	componentDidMount(){

		var pattern = config.images.urlTemplate;

		var pattern_parts = pattern.split('/');

		console.log(pattern,pattern_parts)
		pattern_parts.map((v,i) => {
			//console.log(v.replace(">","").replace("<",""))
		})
		this.setState({image:config.images.endpoint+'/'+this.props.data.metadata.contentId+"/"+this.props.data.metadata.contentId+"_3_1_Hero.jpg"})
	}

	render() {
		return (
			<div className="col-md-6 image mb-3">
				<div className="card border-0 rounded">
				  <img className="card-img-top" src={this.state.image} alt={this.props.data.metadata.contentId} />
				  <div className="card-body">
				    <h5 className="card-title">{this.props.data.metadata.title}</h5>
				    <p>
				    {this.props.data.metadata.genres.map((value, index) => {
						return (
							<span className="badge text-white p-2 mr-1" key={index}>{value}</span>
						)
					})}
					</p>
				    <p className="card-text">
				    	{this.props.data.metadata.shortDescription}
				    </p>
				    <a className="btn btn-primary" href={this.props.link} role="button">{this.props.cta}</a>
				  </div>
				</div>
			</div>
		)
	}
}
export default VideoThumbnail