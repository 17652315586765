import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';

import VideoThumbnail from './videothumbnail';
import Loading from './loading';
import Error from './error';
import fetchProxy from './lib/fetchProxy';

const config = JSON.parse(localStorage.getItem('aaa_config'));

class LiveList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      videos: [],
      avs: this.props.avs,
      mpd: null,
      drm: null,
      loading: true,
      loadingMessage: 'Loading',
      error: null,
      idToken:null
    };
  }
  
  componentDidMount() {
    Auth.currentSession()
      .then(res => {
        //get current token from cognito auth session
        //let idToken = res.getIdToken().getJwtToken();

        let idToken = res.getIdToken().getJwtToken();
        this.setState({idToken:idToken})
        let request_objects = {
          method: 'GET',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }
        };



       /* let request_objects = {
          method: 'GET'
        };
        */

        let request_url =

        config.player_settings.endpoint +
            '/A/'+
            config.locales.default+
            '/'+
            'PCTV'+
            '/'+
            //'CNPLUS_LATAM_LATAM'+
            config.catalog_settings.tenant+
            '/'+
            'LIVE/CHANNELS';
            //add a filter with: ?filter_brand=tnt
        fetchProxy(request_url, request_objects)
          .then(data => {
            //console.log(data);
            this.setState({ videos: data.resultObj.hits });
            this.setState({ loading: false });
          })
          .catch(err => {
          	console.error('Caught error not logged in: ', err);
		        this.setState({ loading: false });
		        this.setState({
		         error: err.message
		        });
          });
      })
      .catch(err => {
        console.error('Caught error not logged in: ', err);
        this.setState({ loading: false });
        this.setState({
          error: err.message
        });
      });
  }

  getToken() {
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken();
      let idToken = res.getIdToken();
      console.log(idToken.getJwtToken());
      console.log('AccessToken:' + accessToken.getJwtToken());
    });
  }

  render() {
    return (
      <div>
        <Error error={this.state.error} />
        <Loading
          state={this.state.loading}
          message={this.state.loadingMessage}
          locale={this.props.locale}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Channel list</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {this.state.videos.map((value, index) => {
              return (
              	<div className="col-md-4 text-center" key={index}>
               		<a href={"/live/"+value._source.liveChannel.metadata.extendedMetadata.contentId}><img src={value._source.liveChannel.platformVariants[0].logoSmall} /><p>{value._source.liveChannel.metadata.name}</p></a>
               	</div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default LiveList;
