import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import React from 'react';

import { mount, route } from 'navi';
import { Router, View } from 'react-navi';


import Amplify from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';

import Navigation from './navigation';
import Video from './video';
import Profile from './profile';
import VideoList from './videolist';
import EpisodeList from './episodelist';
import LiveList from './livelist';
import Home from './home';
import Login from './login';
import Pair from './pair';
import Editor from './editor';
import MiniPlayer from './miniplayer'
import Config from'./config'


if(localStorage.getItem('aaa_config') === null || localStorage.getItem('aaa_config') === ''){
	
	localStorage.setItem('aaa_config', JSON.stringify(Config));
}

if(localStorage.getItem('miniplayer')){
	
}else{

}



const config = JSON.parse(localStorage.getItem('aaa_config'));

class App extends React.Component {
  	constructor(props) {
  		
	    super(props);
	    Amplify.configure({
		  Auth: config.auth_settings.oidc,
		  miniplayer: localStorage.getItem('miniplayer')
		});

		const analyticsConfig = {
			AWSPinpoint: {
				  // Amazon Pinpoint App Client ID
				  appId: '43cd57e995104faaad16971af9f9cf8e',
				  // Amazon service region
				  region: 'eu-west-1',
				  mandatorySignIn: false,
			}
		};
		  
		Analytics.configure(analyticsConfig);
		this.state = {
			language:config.locales.default			
		}
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
		
		

	}

	parentFunction=(data_from_child)=>{
		console.log('miniplayer:',data_from_child);
        this.setState({miniplayer:data_from_child});
	}
	
	handleLanguageChange(e){
		config.locales.default = e.target.value;
		this.setState({language:e.target.value})
	}
	componentWillMount(){
		this.routes = mount({
		  '/MOVIE/:id': route(req => {
		    //stream is vod or live
		    let id = req.params.id;
		    return {
		      view: <Video locale={this.state.language} id={id}  functionCallFromParent={this.parentFunction.bind(this)}/>
		    };
		  }),
		  '/live/:id': route(req => {
		    //stream is vod or live

		    let id = req.params.id;

		    return {
		      view: <Video locale={this.state.language}  id={id} live={true} functionCallFromParent={this.parentFunction.bind(this)} />
		    };
		  }),
		  '/': route(req => {
		    return {
		      view: <Home locale={this.state.language}  />
		    };
		  }),
		  '/films': route(req => {
		    return {
		      view: <VideoList locale={this.state.language} type={'MOVIE'} />
		    };
		  }),
		  '/live': route(req => {
		    return {
		      view: <LiveList locale={this.state.language}  />
		    };
		  }),
		  '/series': route(req => {
		    return {
		      view: <VideoList locale={this.state.language} type={'SERIES'} />
		    };
		  }),
		  '/SERIES/:id': route(req => {
		    let id = req.params.id;
		    return {
		      view: <EpisodeList locale={this.state.language} id={id} />
		    };
		  }),
		  '/login': route(req => {
		    return {
		      view: <Login locale={this.state.language} />
		    };
		  }),
		  '/pair': route(req => {
		    return {
		      view: <Pair locale={this.state.language} />
		    };
		  }),
		  '/edit': route(req => {
		    return {
		      view: <Editor />
		    };
		  })
		});
	}
	componentDidUpdate(){
		console.log(this.state.language);

	}
	componentDidMount() {

		window.addEventListener('miniplayer',e => console.log(e))
		window.onstorage = (e) => {
			this.setState({'miniplayer':localStorage.getItem('miniplayer')})
		};

		window.addEventListener('miniplayer', function (event) {
			console.log(event);
		});
		
	}
	render() {
    	return (
    		<div>
    			<Navigation locale={this.state.language} />
    			<div  className="container p-3">
	    			<Router routes={this.routes} locale={this.state.language}>
					    <View/>
					  </Router>
				</div>



    		</div>
    	)
    }
}
export default App;