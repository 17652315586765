import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';

import VideoThumbnail from './videothumbnail';
import Loading from './loading';
import Error from './error';

import fetchProxy from './lib/fetchProxy';

const config = JSON.parse(localStorage.getItem('aaa_config'));

class VideoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      videos: [],
      avs: this.props.avs,
      mpd: null,
      drm: null,
      type:this.props.type,
      loading: true,
      loadingMessage: 'Loading',
      error: null
    };
  }
  componentDidMount() {
    Auth.currentSession()
      .then(res => {
        //get current token from cognito auth session
       // let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'GET'
        };
        let request_url =
          config.player_settings.endpoint +
            '/A/'+
            config.locales.default+
            '/'+
            config.catalog_settings.platforms[0]+
            '/'+
            config.player_settings.tenant+
            '/'+
          'TRAY/SEARCH/VOD?filter_objectSubtype='+
          this.state.type+
          '&from=0&to=99';
        fetchProxy(request_url, request_objects)
          //Our fetchProxy function removes the need to transpose the data
          //.then(response => response.json())
          .then(data => {
            this.setState({ videos: data.resultObj.containers });
            this.setState({ loading: false });
          })
          .catch(err => console.error('Caught error: ', err));
      })
      .catch(err => {
        console.error('Caught error not logged in: ', err);
        this.setState({ loading: false });
        this.setState({
          error: err.message
        });
      });
  }

  getToken() {
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken();
      let idToken = res.getIdToken();
      console.log(idToken.getJwtToken());
      console.log('AccessToken:' + accessToken.getJwtToken());
    });
  }

  render() {
    return (
      <div>
        <Error error={this.state.error} />
        <Loading
          state={this.state.loading}
          message={this.state.loadingMessage}
          locale={this.props.locale}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>List</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {this.state.videos.map((value, index) => {
              return (
                <VideoThumbnail
                  data={value}
                  key={index}
                  cta='Watch'
                  link={this.state.type+ '/' + value.id}
                  locale={this.props.locale}
                />
              );
            })}
          </div>
          <div className="row">
            <div className="col">
              <h4>{this.state.videos.length} videos found...</h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
export default VideoList;
