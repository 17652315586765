import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
//AWS cognito stuff
import Amplify, { Hub, Auth } from 'aws-amplify';

let config = JSON.parse(localStorage.getItem('aaa_config'));

class Cognito extends React.Component {
  constructor(props) {
    super(props);
    //set initial state
    this.state = {
      user: null,
      attributes: null,
      avs: null,
      signInState: 'signed_out'
    };
  }
  
  componentDidMount() {
    //check to see if the user is logged in already
    //ad listener for auth events
    if(!Auth.currentSession()){
    	localStorage.removeItem('amplify-signin-with-hostedUI');
    }else{
    	//if there is a session, set the region to match the session payload
		Auth.currentSession()
		.then( payload =>{
		 	console.log(payload);
		 	//this overwrites the default AVS tenant in the config file
		 	  config.catalog_settings.tenant = payload.idToken.payload.catalog;
        config.player_settings.tenant = payload.idToken.payload.catalog;
        console.log(config);
		    localStorage.setItem('aaa_config', JSON.stringify(config));
		    this.setState({
	          user: payload,
	          attributes: null,
	          signInState: 'signed_in',
	          avs: null
	        });
		});    
    }
    Hub.listen('auth', data => {
      const { payload } = data;
      console.log('A new auth event has happened: ', data);

      try{
        if (payload.data.signInUserSession !== null) {
          config.catalog_settings.tenant = payload.data.signInUserSession.idToken.payload.catalog
          config.player_settings.tenant = payload.data.signInUserSession.idToken.payload.catalog
          console.log(config);
          localStorage.setItem('aaa_config', JSON.stringify(config));
        }
      } catch(err) {
        //console.log(err);
      }

      if (payload.event === 'signIn') {
        //store payload in user state
        console.log('Sign in');
        //set the region from session payload
        this.setState({
          user: payload,
          attributes: null,
          signInState: 'signed_in',
          avs: null
        });

        Auth.currentAuthenticatedUser().then(
          user => {
            const { attributes } = user;
            console.log(attributes)
          });
      }
      if (payload.event === 'signOut') {
        //remove user from state
        console.log('Sign out');
        this.setState({
          user: null,
          attributes: null,
          signInState: 'signed_out',
          avs: null
        });
        localStorage.removeItem('amplify-signin-with-hostedUI');
      }
    });
  }

  render() {
    /*

		FOR GERERIC AWS UI:
		<Authenticator provider='WarnerMedia />'
		

		Below we are checking to see if a user is logged in - you can use this method across all classes
		*/
    if (this.state.signInState === 'signed_in') {
      return (
        <div>
          <button className="ml-3 btn btn-primary text-white" onClick={() => Auth.signOut()}>
            Logout
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            className="ml-3 btn btn-primary text-white"
            onClick={() => Auth.federatedSignIn({ provider: 'WMID' })}
          >
            Login
          </button>
        </div>
      );
    }
  }
}

export default Cognito;
